import React from "react";
import { Link } from "gatsby";
import { Style } from "./styled";

export function Footer() {
  return (
    <Style>
      <div className={"footer-wrapper"}>
        <div className={"left"}>
          <Link to={"/impressum"}>Impressum</Link>
        </div>

        <div className={"right"}>
          <Link to={"/datenschutz"}>Datenschutzerklärung</Link>
        </div>
      </div>
    </Style>
  );
}
