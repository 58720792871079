import React, { useEffect, useState } from "react";
import { Placeholder, Styles } from "./styled";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { dimensions } from "../../constants/dimensions";

export default function Header() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (typeof window === "undefined") return;
    window.addEventListener("scroll", onScroll);
    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("resize", onResize);
    };
  });

  function onResize() {
    if (mobileMenuOpen) setMobileMenuOpen(false);
  }

  function onScroll() {
    if (
      window.scrollY > 0 &&
      document.body.clientHeight > 1.1 * window.innerHeight
    ) {
      shrinkMenu();
    } else {
      if (window.innerWidth < 930) return;
      extendMenu();
    }
  }

  function shrinkMenu() {
    if (typeof window === "undefined") return;
    document.getElementById("logo-link").style.height =
      dimensions.headerHeightSmall;
    document.getElementById("placeholder").style.height =
      dimensions.headerHeightSmall;
    document.getElementById("nav-ul").style.height =
      dimensions.headerHeightSmall;
  }

  function extendMenu() {
    if (typeof window === "undefined") return;
    document.getElementById("logo-link").style.height = "105px";
    document.getElementById("placeholder").style.height =
      dimensions.headerHeightLarge;
    document.getElementById("nav-ul").style.height =
      dimensions.headerHeightLarge;
  }

  return (
    <>
      <Styles>
        <div className={"menu-wrapper"}>
          <div className={"logo"} id={"logo"}>
            <Link to={"/"} id={"logo-link"}>
              <StaticImage
                src="../../images/logo.png"
                alt="Logo Gartenbau Dannenfelser"
              />
            </Link>
          </div>

          <nav>
            <ul className={"nav-ul"} id={"nav-ul"}>
              <li className={"menu-link"}>
                <Link to={"/geschichte"}>Geschichte</Link>
              </li>
              <li className={"menu-link"}>
                <Link to={"/philosophie"}>Philosophie</Link>
              </li>
              <li className={"menu-link"}>
                <Link to={"/ansprechpartner"}>Ansprechpartner</Link>
              </li>
              <li className={"menu-link"}>
                <Link to={"/offene-stellen"}>Offene Stellen</Link>
              </li>
              <li className={"menu-link"}>
                <Link to={"/leistungen"}>Leistungen</Link>
              </li>
              <li className={"menu-link"}>
                <Link to={"/kontakt"}>Kontakt</Link>
              </li>
            </ul>
          </nav>

          <div className="hamburger">
            <button
              id="hamburger-button"
              onClick={() => {
                setMobileMenuOpen(!mobileMenuOpen);
                shrinkMenu();
              }}
              aria-label={"Menü"}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <line x1="80" y1="160" x2="432" y2="160" />
                <line x1="80" y1="256" x2="432" y2="256" />
                <line x1="80" y1="352" x2="432" y2="352" />
              </svg>
            </button>
          </div>
        </div>

        <div
          id="mobile-nav"
          className="mobile-nav"
          style={{ height: mobileMenuOpen ? "340px" : "0" }}
        >
          <nav>
            <ul>
              <li>
                <Link to={"/geschichte"}>Geschichte</Link>
              </li>
              <li>
                <Link to={"/philosophie"}>Philosophie</Link>
              </li>
              <li>
                <Link to={"/ansprechpartner"}>Ansprechpartner</Link>
              </li>
              <li>
                <Link to={"/offene-stellen"}>Offene Stellen</Link>
              </li>
              <li>
                <Link to={"/leistungen"}>Leistungen</Link>
              </li>
              <li>
                <Link to={"/kontakt"}>Kontakt</Link>
              </li>
            </ul>
          </nav>
        </div>
      </Styles>

      <Placeholder id={"placeholder"} />
    </>
  );
}
