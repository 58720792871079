import styled from "styled-components";
import { colors } from "../../constants/colors";
import { dimensions } from "../../constants/dimensions";

export const Style = styled.div`
  bottom: 0;

  height: ${dimensions.footerHeight};
  width: 100%;

  background-color: ${colors.greys["700"]};

  .footer-wrapper {
    max-width: ${dimensions.maxWidth};
    height: ${dimensions.footerHeight};
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .left,
  .right {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-decoration: none;
    text-align: center;

    width: 100%;
    height: 100%;
    box-sizing: border-box;

    font-size: 14px;

    &:hover,
    &:focus {
      color: ${colors.primary.light} !important;
    }
  }
`;
