import React from "react";
import { Helmet } from "react-helmet";

const meta = {
  DEFAULT_TITLE: "Gartenbau Dannenfelser",
  DEFAULT_DESCRIPTION:
    "Als erfolgreiches, regional ansässiges Garten- und Landschaftsbauunternehmen, sehen wir unsere Stärken in unserer langjährigen Erfahrung im Umgang mit Mensch, Material und Natur. Wir arbeiten zuverlässig, verantwortungs- und kostenbewusst.",
};

export default function Meta({
  baseUrl,
  title,
  description,
  image,
  location,
  slug,
  imageHeight,
  imageWidth,
}) {
  const defaultImage = "https://gartenbau-dannenfelser.de/meta-image.jpg";
  const defaultWidth = 1200;
  const defaultHeight = 1200;

  return (
    <Helmet>
      {/* Language for screen readers */}
      <html lang="de" />

      {/* Char set */}
      <meta charSet="utf-8" />

      {/* Title for browser and search engines */}
      <title>{title || meta.DEFAULT_TITLE}</title>
      <meta name="title" content={title || meta.DEFAULT_TITLE} />

      {/* Description for search engines and social media */}
      <meta
        name="description"
        content={description || meta.DEFAULT_DESCRIPTION}
      />
      <meta
        property="og:description"
        content={description || meta.DEFAULT_DESCRIPTION}
      />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={location?.href || `${baseUrl}${slug}`} />
      <meta property="og:title" content={title || meta.DEFAULT_TITLE} />
      <meta
        property="og:description"
        content={description || meta.DEFAULT_DESCRIPTION}
      />
      <meta
        property="og:image"
        content={image ? `${baseUrl}${image}` : defaultImage}
      />
      <meta property="og:image:width" content={imageWidth || defaultWidth} />
      <meta property="og:image:height" content={imageHeight || defaultHeight} />

      {/* Twitter */}
      <meta property="twitter:card" content="summary" />
      <meta
        property="twitter:url"
        content={location?.href || `${baseUrl}${slug}`}
      />
      <meta property="twitter:title" content={title || meta.DEFAULT_TITLE} />
      <meta
        property="twitter:description"
        content={description || meta.DEFAULT_DESCRIPTION}
      />
      <meta
        property="twitter:image"
        content={image ? `${baseUrl}${image}` : defaultImage}
      />
    </Helmet>
  );
}
