export const colors = {
  primary: {
    default: "hsl(139, 83%, 14%)",
    light: "hsl(139, 83%, 30%)",
    lighter: "hsl(86, 55%, 51%)",
  },

  // greys
  greys: {
    900: "hsl(0, 0%, 6%)",
    800: "hsl(0, 0%, 17%)",
    700: "hsl(0, 0%, 28%)",
    600: "hsl(0, 0%, 39%)",
    500: "hsl(0, 0%, 50%)",
    400: "hsl(0, 0%, 61%)",
    300: "hsl(0, 0%, 72%)",
    // used for input borders
    250: "hsl(0, 0%, 80%)",
    200: "hsl(0, 0%, 83%)",
    150: "hsl(0, 0%, 88%)",
    125: "hsl(0, 0%, 91%)",
    100: "hsl(0, 0%, 94%)",
    50: "hsl(0, 0%, 96%)",
  },
};
