import styled from "styled-components";
import "@fontsource/montserrat";
import { colors } from "../../constants/colors";
import "@fontsource/montserrat/500.css";
import "@fontsource/montserrat/600.css";
import { spacing } from "../../constants/spacing";
import { dimensions } from "../../constants/dimensions";

export const Page = styled.div`
  max-width: ${dimensions.maxWidth};
  margin: 0 auto;
  min-height: calc(
    100vh - ${dimensions.headerHeightSmall} - ${dimensions.footerHeight}
  );
  padding: ${spacing.spaceXl} ${spacing.spaceMd} ${spacing.spaceMd}
    ${spacing.spaceMd};
  font-family: "Montserrat", Helvetica, sans-serif;
  font-size: 1.3rem;
  box-sizing: border-box;

  .lightgreen {
    color: ${colors.primary.lighter};
  }

  a {
    color: ${colors.primary.light};
    text-decoration: none;

    &:hover,
    &:focus {
      text-decoration: underline dashed ${colors.primary.light};
    }
  }

  h1 {
    font-size: 3.5rem;
    margin: 1rem 0;
  }

  .bold {
    font-weight: 600;
  }

  // @media (max-width: 600px) {
  //   padding: ${spacing.spaceXs};
  // }

  @media (max-width: 940px) {
    padding: ${spacing.spaceSm};
  }

  @media (max-width: 600px) {
    padding: ${spacing.space2xs};

    h1 {
      font-size: 2.2rem;
    }
  }
`;

export const Section = styled.section``;
