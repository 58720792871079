import React from "react";
import Header from "./Header/Header";
import GlobalStyle from "./Misc/GlobalStyle";
import { Page } from "./Misc/styled";
import { Footer } from "./Footer/Footer";
import Meta from "./Meta";

export default function Layout({
  children,
  title,
  description,
  baseUrl,
  location,
  slug,
  image,
  imageWidth,
  imageHeight,
}) {
  return (
    <>
      <GlobalStyle />
      <Meta
        title={title}
        description={description}
        baseUrl={baseUrl}
        location={location}
        slug={slug}
        image={image}
        imageWidth={imageWidth}
        imageHeight={imageHeight}
      />
      <Header />
      <Page>{children}</Page>
      <Footer />
    </>
  );
}
