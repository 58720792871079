import { createGlobalStyle } from "styled-components";
import "@fontsource/montserrat";

const GlobalStyle = createGlobalStyle`
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: "Montserrat", Helvetica, sans-serif;
    
    background-color: hsl(139, 83%, 97%);
    
  }

  //#___gatsby {
  //  height: 100%;
  //}
  //
  //#gatsby-focus-wrapper {
  //  height: 100%;
  //}
  
  button, label {
    font-family: "Montserrat", Helvetica, sans-serif;
    font-size: 1rem;
  }
  
  input, textarea {
    font-size: 1rem;
  }
`;

export default GlobalStyle;
