import styled from "styled-components";
import { spacing } from "../../constants/spacing";
import { colors } from "../../constants/colors";
import { dimensions } from "../../constants/dimensions";

export const Styles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;

  background-color: ${colors.primary.default};

  a {
    &:hover,
    &:focus {
      color: ${colors.primary.lighter} !important;
    }
  }

  nav {
    display: contents;
  }

  .menu-wrapper {
    display: flex;
    max-width: ${dimensions.maxWidth};
    width: 100%;
    min-height: 64.8px;
    margin: 0 auto;
  }

  .logo {
    width: 256px;
    height: 71.2px;

    min-width: 256px;
    margin: 0 auto 0 0;

    a {
      position: absolute;
      top: 0;
      //left: 0;
      width: 256px;
      height: 102px;
      //max-height: 71.2px;
      padding: ${spacing.space2xs};
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;

      transition: height 400ms ease-in-out;
      background-color: white;
      box-shadow: 0 0 18px rgba(0, 0, 0, 0.6);
      border-radius: 4px;
    }
  }

  .nav-ul {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: ${dimensions.headerHeightLarge};
    margin: 0;

    transition: height 400ms ease-in-out;

    padding: 0;
    flex-wrap: wrap;

    list-style-type: none;

    li {
      font-size: 1.35rem;
      line-height: 2;
      font-weight: 300;
      margin: 0 auto 0 auto;

      a {
        display: block;
        color: white;
        text-align: center;
        padding: 14px 20px;
        text-decoration: none;
      }
    }
  }

  .hamburger {
    display: none;
    margin-right: ${spacing.spaceMd};

    button {
      display: flex;
      color: white;
      text-align: center;
      padding: 14px 4px;
      text-decoration: none;
      background-color: transparent;
      border-style: none;

      svg {
        height: 42px;
        width: 42px;
        stroke: white;
        stroke-width: 32px;
      }
    }
  }

  .mobile-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    // padding-top: ${spacing.spaceMd};

    overflow: hidden;

    transition: all 500ms ease-in-out;

    ul {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0 0 0 ${spacing.spaceMd};

      list-style-type: none;

      li + li {
        margin-top: ${spacing.spaceXs};
      }

      li {
        font-size: 1.35rem;
        line-height: 2;
        font-weight: 300;

        a {
          display: block;
          color: white;
          text-align: center;
          padding: 0px 0;
          text-decoration: none;
        }
      }
    }
  }

  @media (max-width: 1300px) {
    .logo {
      margin: 0;
    }

    .nav-ul {
      height: auto !important;
      width: 700px;
      margin: 0 auto;

      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: repeat(2, 1fr);
      grid-column-gap: 0;
      grid-row-gap: 0;

      li {
        margin: 0;
      }
    }
  }

  @media (max-width: 940px) {
    flex-direction: column;

    ul {
      width: 100%;
      padding-left: ${spacing.spaceXs};
    }

    .hamburger {
      display: flex !important;
    }

    .menu-link {
      display: none;
    }

    .mobile-nav {
      display: flex;
      flex-direction: column;
    }

    .logo {
      a {
        height: ${dimensions.headerHeightSmall};
      }
    }
  }
`;

export const Placeholder = styled.div`
  min-height: ${dimensions.headerHeightSmall};
  width: 100%;

  @media (max-width: 1300px) {
    height: 142.4px !important;
  }

  @media (max-width: 940px) {
    height: ${dimensions.headerHeightSmall} !important;
    .logo {
      a {
        height: ${dimensions.headerHeightSmall};
      }
    }
  }
`;
